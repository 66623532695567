import React from 'react'
import { Link } from 'gatsby'
import monoLogo from '../images/logo-dark.svg'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { useLocation } from '@reach/router'

export default props => {
    const loc = useLocation()
    return (
        <div className='fixed top-0 left-0 right-0 z-40'>
            <div
                className={`${
                    props.visible ? 'opacity-100' : '-translate-y-16 opacity-0'
                } transform transition duration-300 w-full px-0 sm:px-2 lg:px-4`}
            >
                <div className='max-w-7xl mx-auto pl-2 sm:pl-6 text-white h-full pr-2 sm:pr-3 rounded bg-white shadow-xl flex justify-between items-center'>
                    <div className='flex h-full'>
                        <Link to='/' className='flex flex-shrink-0 items-center group mr-4 sm:mr-8'>
                            <img
                                alt='Mono FM'
                                width='95px'
                                height='100%'
                                className='text-gray-300 group-hover:text-gray-500 transform duration-150 scale-95 group-hover:scale-100'
                                src={monoLogo}
                            />
                        </Link>
                        <Link
                            className='py-4 px-1 sm:px-4 text-sm md:text-base leading-none h-full border-b-2 border-transparent hover:border-black text-gray-900 hover:text-black'
                            to='/#pricing'
                        >
                            Pricing
                        </Link>
                        <Link
                            className='hidden sm:block py-4 px-4 text-sm md:text-base leading-none h-full border-b-2 border-transparent hover:border-black text-gray-900 hover:text-black'
                            to='/#features-podcast'
                        >
                            Features
                        </Link>
                        <Link
                            className='py-4 px-1 sm:px-4 text-sm md:text-base leading-none h-full border-b-2 border-transparent hover:border-black text-gray-900 hover:text-black'
                            to='/faqs'
                        >
                            FAQs
                        </Link>
                    </div>
                    <div className='flex flex-shrink-0 h-full items-center text-sm space-x-4 text-gray-500 py-1'>
                        <OutboundLink
                            eventAction='signup_click'
                            className='bg-gray-800 border border-transparent text-white hover:bg-white hover:text-black hover:border-gray-800 rounded px-2 text-xs sm:text-sm sm:px-3 py-1 sm:py-2 transition duration-150'
                            rel='nofollow'
                            href={`https://app.mono.fm/signup${loc.search}`}
                        >
                            <span className='hidden md:block'>Start 30-Day Free Trial</span>
                            <span className='hidden sm:block md:hidden'>Start 30-Day Trial</span>
                            <span className='block sm:hidden'>Start Trial</span>
                        </OutboundLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
