import React from 'react'

export default props => {
    const [classes, setClasses] = React.useState([
        `scale-105 z-30 opacity-100 translate-y-0`,
        `scale-90 z-20 opacity-75 translate-y-4`,
        `scale-75 z-10 opacity-50 translate-y-8`,
    ])
    React.useEffect(() => {
        const timer = setInterval(() => {
            let arr = [...classes]
            const last = arr.pop()
            setClasses([last, ...arr])
        }, 2500)
        return () => clearInterval(timer)
    }, [classes])

    const getClass = idx => {
        return classes[idx]
    }

    return (
        <ul className='relative flex items-center justify-center'>
            {props.items.map((item, i) => (
                <li
                    key={i}
                    className={`${getClass(
                        i,
                    )} absolute flex-center pin-0 bg-white shadow-xl sm:w-64 md:w-48 xl:w-64 md:h-32 rounded origin-bottom transform transition duration-700`}
                >
                    {item}
                </li>
            ))}
        </ul>
    )
}
