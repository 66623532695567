import React from 'react'
import SEO from '../components/seo.js'
import PricingSimulator from '../components/simulator.js'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import FloatingHeader from '../components/floating-header'
import Carousel from '../components/carousel'
import { useInView } from 'react-intersection-observer'
import { useLocation } from '@reach/router'
import qs from 'query-string'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

export default props => {
    const [pricingOpen, setPricingOpen] = React.useState(false)
    const [ref, inView] = useInView({ threshold: 0 })

    const loc = useLocation()

    let redirectParams = `?redirectTo=/import`
    if (loc.search) {
        const qParams = qs.parse(loc.search)
        qParams.redirectTo = '/import'
        redirectParams = `?${qs.stringify(qParams)}`
    }

    return (
        <>
            <SEO title='Mono FM - Pay as you go podcast hosting' />
            <FloatingHeader visible={!inView} />
            <div className='sm:mt-8 mb-6 max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 gap-8 px-2 sm:pl-16 sm:pr-8 py-14'>
                <div className='my-auto px-4 sm:px-0'>
                    <h1 className='text-3xl sm:text-5xl font-bold pb-6 leading-tight text-teal-700'>
                        Pay as you Go <br />
                        Podcast Hosting.
                    </h1>
                    <p className='max-w-sm'>
                        Mono is the ideal podcast host for new and growing podcasts. Get everything
                        you need to create podcast and pay only for the downloads your episodes get.
                    </p>
                    <div className='pt-2 lg:pt-0 lg:space-x-4'>
                        <div ref={ref} className='block lg:inline-flex flex-col mb-2 lg:mb-8'>
                            <OutboundLink
                                eventAction='signup_click'
                                className='block text-center lg:inline-flex items-center group shadow-lg bg-gray-900 text-white transform hover:-translate-y-0.25 transition duration-300 rounded py-4 sm:py-3 px-10 mt-2 lg:mt-10 hover:shadow-xl'
                                href={`https://app.mono.fm/signup${loc.search}`}
                                rel='nofollow'
                            >
                                Get First Month Free
                            </OutboundLink>
                            <p className='text-sm italic text-center mt-3'>No credit card needed</p>
                        </div>
                    </div>
                </div>
                <div className='text-center'>
                    <div className='max-w-sm bg-white mx-auto md:mr-0 md:ml-auto flex flex-col shadow-lg hover:shadow-xl transition duration-150 rounded-md overflow-hidden '>
                        <div className='pt-8 pb-8 flex flex-col text-black font-medium items-center '>
                            <span className='text-5xl font-semibold'>
                                $1
                                <span className='text-2xl font-normal text-gray-600'>/month</span>
                            </span>
                            <span className='font-normal text-sm'>per 50GB of bandwidth used</span>
                            <span className='font-normal text-sm'>(~ 1500 downloads)</span>
                        </div>
                        <ul className='flex-1 mx-auto space-y-3 text-gray-600'>
                            <li className='flex items-center'>
                                <svg
                                    className='w-5 h-5 mr-2 text-gray-700'
                                    fill='currentColor'
                                    viewBox='0 0 20 20'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                                        clipRule='evenodd'
                                    ></path>
                                </svg>
                                Unlimited Shows
                            </li>
                            <li className='flex items-center'>
                                <svg
                                    className='w-5 h-5 mr-2 text-gray-700'
                                    fill='currentColor'
                                    viewBox='0 0 20 20'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                                        clipRule='evenodd'
                                    ></path>
                                </svg>
                                Podcast Website
                            </li>
                            <li className='flex items-center'>
                                <svg
                                    className='w-5 h-5 mr-2 text-gray-700'
                                    fill='currentColor'
                                    viewBox='0 0 20 20'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                                        clipRule='evenodd'
                                    ></path>
                                </svg>
                                Team Collaboration
                            </li>
                            <li className='flex items-center'>
                                <svg
                                    className='w-5 h-5 mr-2 text-gray-700'
                                    fill='currentColor'
                                    viewBox='0 0 20 20'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                                        clipRule='evenodd'
                                    ></path>
                                </svg>
                                Analytics
                            </li>
                        </ul>
                        <div className='pt-6 pb-6'></div>
                    </div>
                </div>
            </div>
            <div className='mx-1 sm:mx-0 bg-white anchor px-2 sm:px-6 sm:pl-10 pt-16 pb-16'>
                <i id='features-podcast' />
                <div className='max-w-6xl mx-auto lg:flex justify-center items-center'>
                    <div className='lg:flex flex-col text-center lg:text-left items-start mx-auto lg:mx-8'>
                        <h2 className='font-bold text-2xl sm:text-4xl leading-tight px-2'>
                            Distribute Your Podcast Everywhere
                        </h2>
                        <p className='text-lg px-2 mx-auto lg:m-0 max-w-sm mt-4'>
                            Create your shows with Mono and easily distribute to Apple Podcast,
                            Spotify, Google Podcasts and more.
                        </p>
                    </div>
                    <div className='w-full h-96 md:h-80 md:space-x-4 lg:space-x-12 flex flex-col md:flex-row items-center max-w-xl mx-auto'>
                        <div className='flex flex-col md:flex-row mt-6 md:mr-24 lg:mr-32 items-center'>
                            <Img fixed={props.data.monoCard.childImageSharp.fixed} />
                            <div className='transform rotate-90 md:rotate-0 mt-1 md:mt-0 mb-12 md:mb-0'>
                                <Img fixed={props.data.arrows.childImageSharp.fixed} />
                            </div>
                        </div>
                        <div className='mx-auto mt-12 md:mt-0'>
                            <Carousel
                                items={[
                                    <Img fixed={props.data.apple.childImageSharp.fixed} />,
                                    <Img fixed={props.data.spotify.childImageSharp.fixed} />,
                                    <Img fixed={props.data.google.childImageSharp.fixed} />,
                                    <Img fixed={props.data.stitcher.childImageSharp.fixed} />,
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='mx-1 sm:mx-0 anchor px-2 sm:px-6 sm:pl-10 pt-16 pb-16'>
                <i id='features-collaborate' />
                <div className='max-w-6xl mx-auto md:flex justify-center items-center'>
                    <div className='md:flex flex-col text-center md:text-left items-start mx-auto md:mx-8'>
                        <h2 className='font-bold text-2xl sm:text-4xl leading-tight px-2'>
                            Team Collaboration
                        </h2>
                        <p className='text-lg px-2 mx-auto md:m-0 max-w-sm mt-4'>
                            Invite and collaborate with as many members as you need. <br />
                            Work together and create great content.
                        </p>
                    </div>
                    <div className='w-full max-w-xl mx-auto'>
                        <Img fluid={props.data.collaborate.childImageSharp.fluid} />
                    </div>
                </div>
            </div>
            <div className='mx-1 sm:mx-0 anchor bg-white px-2 sm:px-6 sm:pl-10 pt-16 pb-16'>
                <i id='features-shows' />
                <div className='max-w-6xl mx-auto md:flex justify-center items-center'>
                    <div className='md:flex flex-col text-center md:text-left items-start mx-auto md:mx-8'>
                        <h2 className='font-bold text-2xl sm:text-4xl leading-tight px-2'>
                            Unlimited Shows
                        </h2>
                        <p className='text-lg px-2 mx-auto md:m-0 max-w-sm mt-4'>
                            We don't put arbitrary restrictions on your creativity. Create as many
                            shows and episodes as you need.
                        </p>
                    </div>
                    <div className='w-full max-w-xl mx-auto'>
                        <Img fluid={props.data.unlimitedShows.childImageSharp.fluid} />
                    </div>
                </div>
            </div>
            <div className='mx-1 sm:mx-0 anchor px-2 sm:px-6 sm:pl-10 pt-16 pb-16'>
                <i id='features-shows' />
                <div className='max-w-6xl mx-auto md:flex justify-center items-center'>
                    <div className='md:flex flex-col text-center md:text-left items-start mx-auto md:mx-8'>
                        <h2 className='font-bold text-2xl sm:text-4xl leading-tight px-2'>
                            Your Podcast Website
                        </h2>
                        <p className='text-lg px-2 mx-auto md:m-0 max-w-sm mt-4'>
                            Your show gets a dedicated podcast site containing all your relevant
                            links. No "premium" or "ultimate" plan necessary.
                        </p>
                    </div>
                    <div className='w-full max-w-xl mx-auto'>
                        <Img fluid={props.data.website.childImageSharp.fluid} />
                    </div>
                </div>
            </div>

            <div className='mx-1 sm:mx-0 anchor bg-white px-2 sm:px-6 sm:pl-10 pt-16 pb-16'>
                <i id='features-import' />
                <div className='max-w-6xl mx-auto'>
                    <h2 className='font-bold text-2xl sm:text-4xl leading-tight sm:text-center px-2'>
                        Migrate Existing Podcast to Mono
                    </h2>
                    <p className='text-lg px-2 sm:text-center mx-auto max-w-2xl mt-4'>
                        Already host a podcast somewhere? Instantly migrate your existing podcast to
                        Mono. No hacking required
                    </p>
                    <div className='flex flex-col items-center mt-8 mb-8'>
                        <a
                            rel='nofollow'
                            href={`https://app.mono.fm/signup${redirectParams}`}
                            className='shadow-lg bg-gray-900 text-white transform hover:-translate-y-0.25 transition duration-300 rounded py-3 px-10 hover:shadow-xl'
                            //@Todo import image
                        >
                            Import your Podcast
                        </a>
                    </div>
                </div>
            </div>
            <div className='mx-1 sm:mx-0 anchor px-2 sm:px-6 sm:pl-10 pt-16 pb-16'>
                <i id='features-analytics' />
                <div className='max-w-6xl mx-auto md:flex justify-center items-center'>
                    <div className='md:flex flex-col text-center md:text-left items-start mx-auto md:mx-8'>
                        <h2 className='font-bold text-2xl sm:text-4xl leading-tight px-2'>
                            Podcast Analytics
                        </h2>
                        <p className='text-lg px-2 mx-auto md:m-0 max-w-sm mt-4'>
                            View your downloads per episode, popular episodes, geographical
                            location, trends, apps and more.
                        </p>
                    </div>
                    <div className='w-full max-w-xl mx-auto'>
                        <Img fluid={props.data.analytics.childImageSharp.fluid} />
                    </div>
                </div>
            </div>
            <div className='mx-1 sm:mx-0 anchor bg-white px-2 sm:px-6 sm:pl-10 pt-16 pb-16'>
                <i id='features-redirect' />
                <div className='max-w-6xl mx-auto'>
                    <h2 className='font-bold text-2xl sm:text-4xl leading-tight sm:text-center px-2'>
                        Never Locked In
                    </h2>
                    <p className='text-lg px-2 sm:text-center mx-auto max-w-2xl mt-4'>
                        We never lock you in. Easily migrate your shows to other providers with a
                        single click.
                    </p>
                </div>
            </div>

            <div className='mx-1 sm:mx-0 anchor px-2 sm:px-6 sm:pl-10 pt-8 pb-8 sm:pt-16 sm:pb-16'>
                <i id='pricing' />
                <div className='max-w-6xl mx-auto'>
                    <h2 className='font-bold text-2xl sm:text-4xl leading-tight sm:text-center px-2'>
                        Start with just <span className='text-teal-600'>$1/month</span>.
                        <br />
                        Then grow with us.
                    </h2>
                    <p className='text-lg px-2 sm:text-center mx-auto max-w-2xl mt-4'>
                        With Mono your price scales fairly with your podcast growth. <br />
                        Pay just $1 per 50GB of downloads per month. (50 GB covers about 1500
                        downloads)
                    </p>
                </div>
                <div className='mt-6'>
                    <PricingSimulator />
                </div>
            </div>
            <div className='mx-1 sm:mx-0 anchor bg-white px-2 bg-white sm:px-6 sm:pl-10 pt-16 pb-16'>
                <i id='features-stats' />
                <div className='max-w-6xl mx-auto'>
                    <h2 className='font-bold text-2xl sm:text-4xl leading-tight sm:text-center px-2'>
                        Always There to Help
                    </h2>
                    <p className='text-lg px-2 sm:text-center mx-auto max-w-2xl mt-4'>
                        We'll be super happy to help you out if you have any issue. Just send an
                        email to{' '}
                        <a rel='nofollow' href='mailto:support@mono.fm'>
                            support@mono.fm
                        </a>
                    </p>
                    <ul className='sm:flex justify-center items-center text-lg mt-10 sm:divide-x divide-gray-200'>
                        <li className='px-8 flex flex-col items-center'>
                            <span className='text-5xl font-semibold'>23,000</span>
                            <span className='uppercase text-sm tracking-wide font-medium'>
                                requests served
                            </span>
                        </li>
                        <li className='px-8 mt-3 sm:mt-0 flex flex-col items-center'>
                            <span className='text-5xl font-semibold'>17,000</span>
                            <span className='uppercase text-sm tracking-wide font-medium'>
                                Downloads
                            </span>
                        </li>
                        <li className='px-8 mt-3 sm:mt-0 flex flex-col items-center'>
                            <span className='text-5xl font-semibold'>300GB</span>
                            <span className='uppercase text-sm tracking-wide font-medium'>
                                Data served
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export const query = graphql`
    query Images {
        arrows: file(name: { eq: "arrows" }) {
            childImageSharp {
                fixed(width: 80, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        monoCard: file(name: { eq: "monoCard" }) {
            childImageSharp {
                fixed(width: 200, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        apple: file(name: { eq: "apple" }) {
            childImageSharp {
                fixed(width: 200, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        spotify: file(name: { eq: "spotify" }) {
            childImageSharp {
                fixed(width: 200, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        google: file(name: { eq: "google" }) {
            childImageSharp {
                fixed(width: 200, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        stitcher: file(name: { eq: "stitcher" }) {
            childImageSharp {
                fixed(width: 200, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        analytics: file(name: { eq: "analytics" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        unlimitedShows: file(name: { eq: "unlimited-shows" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        collaborate: file(name: { eq: "collaborate" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        distribute: file(name: { eq: "distribute" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        website: file(name: { eq: "website" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
