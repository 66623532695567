import React from 'react'

const renderDownloadsHint = value => {
    if (value < 400) return 'Starter Show' // (Top 50%)'
    if (value < 1000) return 'Growing Show' // (Top 20%)'
    if (value < 2000) return 'Popular Show' // (Top 15%)'
    if (value < 3000) return 'Very Popular Show' // Show (Top 10%)'
    return 'Very Popular Show' // (Top 10%)'
}

const calcPrice = (numUploads, numDownloads, episodeDuration) => {
    const BANDWIDTH_COST = 0.02
    const BANDWIDTH_STEP = 50
    let result = { price: 0, bandwidthUsed: 0 }

    const SIZE_PER_MIN = 1
    const mediaUploadedInGB = (numUploads * episodeDuration * SIZE_PER_MIN) / 1024
    const mediaDownloadedInGB = mediaUploadedInGB * numDownloads

    let bandwidthCost = Math.ceil(mediaDownloadedInGB / BANDWIDTH_STEP)

    let price = 0
    if (bandwidthCost) price += bandwidthCost
    if (price < 1) price = 1

    result = {
        price: price,
        bandwidthUsed: mediaDownloadedInGB,
    }
    return result
}

export default props => {
    const [numUploads, setNumUploads] = React.useState(1)
    const [numDownloads, setNumDownloads] = React.useState(300)
    const [episodeDuration, setEpisodeDuration] = React.useState(30)

    const monoResult = calcPrice(numUploads, numDownloads, episodeDuration)

    return (
        <div className='bg-white shadow-xl overflow-hidden rounded max-w-3xl mx-auto'>
            <div className='bg-gray-100 text-xs uppercase tracking-wide font-medium text-center py-3 leading-none'>
                <span>Estimate Your Price</span>
            </div>
            <div className='leading-none text-center mt-4'>
                <span className='text-4xl'>
                    ${monoResult.price.toFixed(0)}
                    <span className='text-xl'>/month</span>
                </span>
            </div>
            <div className='mt-2'>
                <div className='space-y-6 sm:px-3 '>
                    <div className='py-3 px-6'>
                        <div className='sm:flex py-2 font-medium justify-between items-end'>
                            <div>
                                <span className='text-base'>
                                    How many downloads do you expect per month?
                                </span>
                            </div>
                            <span className='flex-1 text-right text-lg'>{numDownloads}</span>
                        </div>
                        <input
                            aria-label='Downloads'
                            className='slider w-full'
                            onChange={e => setNumDownloads(parseInt(e.target.value))}
                            type='range'
                            min={0}
                            step={100}
                            max={10000}
                            value={numDownloads}
                        />
                        <div className='md:flex items-center justify-between'>
                            <span className='font-light'>
                                Bandwidth used: {monoResult.bandwidthUsed.toFixed(0)}GB
                            </span>
                            <p className='py-2 text-center italic text-xs'>
                                *For typical 30 minute episodes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
